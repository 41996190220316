<template>
  <div class="modern-color-theme font-poppins fixed inset-0 flex items-center justify-center bg-neutral-100 z-1001" :class="computedClass">
    <slot />
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

defineOptions({
  name: 'VBackdrop'
})

const props = withDefaults(
  defineProps<{
    opaque?: boolean
  }>(),
  {
    opaque: false
  }
)

const computedClass = computed(() => ({
  'bg-opacity-25': !props.opaque
}))
</script>