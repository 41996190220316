<template>
  <h1 class="modern-color-theme font-poppins overflow-hidden overflow-ellipsis" :class="computedClass" :style="{ color }">
    <slot />
  </h1>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { ColorWithShade, Size } from '../../utils/types';
import { convertColorClassToCSS } from '../../utils/utils';

defineOptions({
  name: 'VTitle'
})

const props = withDefaults(
  defineProps<{
    size?: Size
    color?: ColorWithShade
    bold?: boolean
    italic?: boolean
  }>(),
  {
    size: 'sm',
    color: 'neutral',
    bold: false,
    italic: false
  }
)

const computedClass = computed(() => {
  const styles = []

  if (props.size) styles.push(`text-${props.size}`)
  if (props.bold) styles.push('font-bold')
  if (props.italic) styles.push('italic')

  return styles.join(' ')
})

const color = computed(() => convertColorClassToCSS(props.color, 950))
</script>
