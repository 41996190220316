<template>
  <VBackdrop @click="props.closeViaBackdrop && emit('close')">
    <div role="dialog" class="modern-color-theme font-poppins mx-8 p-5 bg-white rounded-lg flex flex-col justify-center text-center shadow-modal" :class="{ 'relative': props.closeViaButton }" :style="computedLayout" v-bind="$attrs" @click.stop>
      <VButton v-if="props.closeViaButton" variant="text" color="neutral" icon :container-props="{ class: 'absolute top-2 right-2' }" @click="emit('close')">
        <VIcon name="Solid/close" />
      </VButton>
      <slot />
    </div>
  </VBackdrop>
</template>
<script setup lang="ts">
import VButton from '@component-library/buttons/VButton.vue';
import VBackdrop from '../layouts/VBackdrop.vue'
import VIcon from '@component-library/labels/VIcon.vue';
import { computed } from 'vue';

defineOptions({
  name: 'VDialog',
  inheritAttrs: false
})

const props = defineProps<{
  width?: string
  height?: string
  closeViaButton?: boolean
  closeViaBackdrop?: boolean
}>()

const emit = defineEmits<{
  close: []
}>()

const computedLayout = computed(() => {
  return {
    minWidth: props.width ?? '500px',
    minHeight: props.height
  }
})
</script>
