<template>
  <div class="modern-color-theme font-poppins">
    <div class="flex gap-x-2 h-6 items-center">
      <template v-if="props.readonly">
        <input :id="id" :checked="modelValue" type="checkbox" :disabled="props.disabled" :indeterminate="props.indeterminate" :tabindex="props.tabindex" class="h-4 w-4 rounded border-neutral-300 focus:ring-blue-500 text-primary-500 disabled:text-neutral-200" @click.prevent.stop>
      </template>
      <template v-else>
        <input :id="id" v-model="modelValue" type="checkbox" :disabled="props.disabled" :indeterminate="props.indeterminate" :tabindex="props.tabindex" class="h-4 w-4 rounded border-neutral-300 focus:ring-blue-500 text-primary-500 disabled:text-neutral-200">
      </template>
      <VSLabel v-if="props.label" :for="id">{{ props.label }}</VSLabel>
    </div>
    <VSDescription v-if="props.description">{{ props.description }}</VSDescription>
  </div>
</template>
<script lang="ts" setup>
import VSDescription from './components/VSDescription.vue'
import VSLabel from './components/VSLabel.vue'
import { useElementId } from '../../utils/utils';

defineOptions({
  name: 'VCheckbox'
})

const props = withDefaults(
  defineProps<{
    id?: string
    label?: string
    description?: string
    disabled?: boolean
    readonly?: boolean
    tabindex?: number | string
    indeterminate?: boolean
  }>(),
  {
    id: undefined,
    disabled: false,
    readonly: false,
    description: undefined,
    label: undefined,
    tabindex: undefined,
    indeterminate: false
  }
)

const id = useElementId(props.id)

const modelValue = defineModel<boolean | undefined | null>({ required: true })
</script>