<template>
  <div class="flex flex-col border-y border-gray-200 mx-4 p-4">
    <div
        v-if="documents.length > 1"
        class="flex justify-between items-center mb-2"
    >
      <label
          for="selectAll"
          class="cursor-pointer"
          v-text="localizeText('templates.archive_dialog.select_all')"
      />
      <input
          id="selectAll"
          v-model="selectAll"
          type="checkbox"
          class="mx-2 cursor-pointer"
          @change="handleSelectAllChange"
      />
    </div>
    <div v-for="document in documents" :key="document.id">
      <div class="flex justify-between items-center">
        <button
            class="text-lg text-blue-500 my-1 cursor-pointer font-medium truncate"
            :title="document.name"
            @click="handleClick(document)"
        >
          {{ document.name }}
        </button>
        <div class="flex items-center">
          <v-checkbox
              v-model="document.checked"
              class="mx-2 cursor-pointer"
              @change="handleCheckboxChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, reactive } from 'vue'
import VCheckbox from "@component-library/inputs/VCheckbox.vue";

const props = defineProps<{
  documents: Record<string, { name: string, formats: string[] }>
}>()

const emit = defineEmits<{
  (eventName: 'selected-documents-change', payload: Array<string>): void
}>()

type Document = {
  id: string;
  name: string;
  checked: boolean;
}

const documents = reactive(Object.entries(props.documents).map(([id, doc]) => ({ ...doc, id, checked: false })));
const localizeText = window.localizeText
const selectAll = ref(false)

const handleSelectAllChange = () => {
  documents.forEach((document) => {
    document.checked = selectAll.value
  })
  const selectedDocumentIds = documents.filter(doc => doc.checked).map(doc => doc.id)
  emit('selected-documents-change', selectedDocumentIds)
}

const handleClick = (document: Document) => {
  document.checked = true
  openDocument(document)
}

const handleCheckboxChange = () => {
  const selectedDocumentIds = documents.filter(doc => doc.checked).map(doc => doc.id)
  emit('selected-documents-change', selectedDocumentIds)
}

const openDocument = (document: Document) => {
  const url = `/documents/${document.id}/continue`
  window.open(url, '_blank')
}
</script>